import React, { createContext, useState, useCallback, useContext } from 'react';

const UserContext = createContext();

const defaultLanguage = 'pt-br';
const LOCALSTORAGE_LANGUAGE = 'anghello_com_br_lang';

function UserProvider({ children }) {
  const [loading, setLoading] = useState(null);
  const [language, setLanguage] = useState(() => {
    const savedValue = localStorage.getItem(LOCALSTORAGE_LANGUAGE);
    if (savedValue) {
      return savedValue;
    }
    return defaultLanguage
  });

  const toggleLoading = useCallback((state) => setLoading(state), []);

  const toggleLanguage = useCallback((state) => {
    setLanguage(state);
    localStorage.setItem(LOCALSTORAGE_LANGUAGE, state);
  }, []);

  return (
    <UserContext.Provider
      value={{
        loading,
        toggleLoading,
        language,
        toggleLanguage
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

function useUser() {
  const context = useContext(UserContext);
  return context;
}

export { useUser, UserProvider }
