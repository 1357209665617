/* Projects.js */
/* @author: anghello */

// import SiteLinks from 'enums/Links';
// import Randomizer from 'images/randomizer.png';

export default [
  {
    imageSrc: 'https://static.anghello.me/img/smartlist-2.png',
    title: 'Smartlist',
    description: 'Plataforma para o gerenciamento de tarefas e projetos.',
    link: 'https://smartlist.app.br',
    target: '_blank'
  },
  {
    imageSrc: 'https://static.anghello.me/img/ferramentas.app.br.png',
    title: 'ferramentas.app.br',
    description: 'Website com diversas ferramentas online para usar no dia a dia',
    link: 'https://ferramentas.app.br',
    target: '_blank'
  },
];
