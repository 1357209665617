import React from "react";
import tw from "twin.macro";
import "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import AppString from "i18n/AppString";

const Container = tw(ContainerBase)`my-8 lg:my-10 bg-blue-800 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const SectionDescriptionCustom = tw.p`mt-4 font-medium leading-relaxed text-secondary-100 max-w-xl`;
const Description = tw(SectionDescriptionCustom)`text-gray-200 text-center mx-auto max-w-screen-md text-lg`;

export default ({
  subheading = "",
  heading = <AppString id='resume_heading' />,
  description = <AppString id='resume_description' />
}) => {
  return (
    <Container id="sobre">
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
