/* SiteHero.js */
/* @author: anghello */

import React from "react";
import tw from "twin.macro";
import "styled-components/macro";
import Header from "components/headers/SiteHeader.js";
import { SectionHeading } from "components/misc/Headings.js";
import { HeroDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import AppString from "i18n/AppString";

import ProfilePicture from "../../images/angelo-silva-v2.png";

const Row = tw.div`flex flex-col md:flex-row justify-between items-center lg:px-12 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`w-full lg:w-3/5 mr-auto lg:mr-0`;
const ImageColumn = tw(Column)`hidden md:block w-1/5 mr-auto lg:mr-0`;
const Heading = tw(SectionHeading)`text-left text-blue-600 leading-snug xl:text-5xl`;
const Description = tw(HeroDescription)`mt-4 text-gray-700`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5`;
const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;
const imageCss = tw`rounded-4xl shadow-2xl`;

export default ({
  heading = <AppString id='hero_heading' />,
  description = <AppString id='hero_description' />,
  primaryButtonUrl = '/#sobre',
  primaryButtonText = <AppString id='hero_primary_button_text' />,
  buttonRounded = true,
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;

  return (
    <>
      <Header />
      <Container>
        <ContentWithVerticalPadding>
          <Row>

            <TextColumn>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <PrimaryButton as="a" href={primaryButtonUrl} css={buttonRoundedCss}>
                {primaryButtonText}
              </PrimaryButton>
            </TextColumn>

            <ImageColumn>
              <IllustrationContainer>
                <img
                  css={imageCss}
                  src={ProfilePicture}
                  alt="Angelo Silva"
                />
              </IllustrationContainer>
            </ImageColumn>

          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
