/* AppString.js */
/* @author: anghello */

import React, { useEffect, useState } from 'react';
import { useUser } from 'context/UserProvider';

import portuguese from './translations/pt-br.json';
import english from './translations/eng.json';

export default ({ id }) => {
  const contextValue = useUser();
  const [language, setLanguage] = useState('pt-br');

  const languages = {
    'pt-br': portuguese,
    'eng': english
  };

  useEffect(() => {
    if (contextValue && contextValue.language) {
      setLanguage(contextValue.language);
    }
  }, [contextValue]);

  return (
    <>{languages[language][id]}</>
  );
};
