import { getCookieConsentValue } from 'react-cookie-consent';

export default function GoogleAnalytics() {
  const cookieName = process.env.REACT_APP_COOKIE_CONSENT_NAME;
  const GA = process.env.REACT_APP_GOOGLE_ANALYTICS;
  const cookieAccepted = getCookieConsentValue(cookieName);

  if (GA === 'enabled' && cookieAccepted) {
    window.gtag('js', new Date());
    window.gtag('config', 'G-KSYHZ6N2NQ');
  }
}
