/* SiteHeader.js */
/* @author: anghello */

import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import "styled-components/macro";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import SiteLinks from 'enums/Links';
import AppString from "i18n/AppString.js";
// import { useUser } from "context/UserProvider.js";
// import brazilFlag from 'images/br.svg';
// import usaFlag from 'images/usa.svg';

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-blue-500 hocus:text-blue-500
`;
export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-blue-600 text-gray-100
  hocus:bg-blue-800 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;
export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;
export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-blue-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);
export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

// const FlagButton = tw.button`p-2`;

export default ({
  logoLink, 
  links, 
  className, 
  collapseBreakpointClass = "lg" 
}) => {
  // const { toggleLanguage, language } = useUser();

  const buttonRoundedCss = tw`rounded-full`;
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/#sobre"><AppString id='header_sobre'/></NavLink>
      <NavLink href="/#projetos"><AppString id='header_projetos' /></NavLink>
      {/* <NavLink css={buttonRoundedCss} href="/#contato"><AppString id='header_contato' /></NavLink> */}
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink css={buttonRoundedCss} href={`mailto:${SiteLinks.email}`} target="_blank" rel="noreferrer noopener">
        <AppString id='header_contato' />
      </PrimaryLink>
      {/* <FlagButton onClick={() => toggleLanguage('pt-br')}>
        <img
          className={`menuFlags ${language === 'pt-br' ? 'active' : ''}`}
          src={brazilFlag}
          alt='pt-br'
        />
      </FlagButton>
      <FlagButton onClick={() => toggleLanguage('eng')}>
        <img
          className={`menuFlags ${language === 'eng' ? 'active' : ''}`}
          src={usaFlag}
          alt='eng'
        />
      </FlagButton> */}
    </NavLinks>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      ANGHELLO
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || navLinks;

  return (
    <Header className={className || "header-light max-w-none"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
          onClick={() => {
            if (showNavLinks) toggleNavbar()
          }}
        >
          {links}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
