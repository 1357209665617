/* App.js */
/* @author: anghello */

import 'tailwindcss/dist/base.css';
import 'styles/globalStyles.css';
import React from 'react';
import 'styled-components/macro';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import GoogleAnalytics from 'helpers/GoogleAnalytics';
import AppProvider from 'context/Provider';

import Home from 'pages/Home.js';
import PrivacyPolicy from 'pages/PrivacyPolicy';
// import Projects from 'pages/Projects';
// import Smartlist from 'pages/projects/Smartlist';

export default function App() {
  const cookieName = process.env.REACT_APP_COOKIE_CONSENT_NAME;

  return (
    <AppProvider>
      <Router>
        <Switch>

          {/*
          <Route path='/projetos'>
            <Projects />
          </Route>
          <Route path='/projeto/smartlist'>
            <Smartlist />
          </Route>
          */}

          <Route path='/politica-de-privacidade'>
            <PrivacyPolicy />
          </Route>
          
          <Route path='/'>
            <Home />
          </Route>

        </Switch>

        <CookieConsent
          location='bottom'
          buttonText='OK'
          cookieName={cookieName}
          style={{ background: '#2B373B', fontSize: '17px' }}
          buttonStyle={{ background: '#3181CE', color: '#ffffff', fontSize: '18px' }}
          expires={100}
          onAccept={() => {
            GoogleAnalytics();
          }}
        >
          Este website coleta cookies para aprimorar a experiência do usuário
        </CookieConsent>
      </Router>
    </AppProvider>
  );
}
