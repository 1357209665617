import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
// import { SectionDescription } from "components/misc/Typography.js";
import AppString from "i18n/AppString";

import defaultCardImage from "images/shield-icon.svg";
import JavascriptIconImage from "images/javascript-icon.svg";
import MobileIconImage from "images/mobile-icon.svg";
import NodeIconImage from "images/nodejs-icon.svg";
import WordpressIconImage from "images/wordpress-icon.svg";
import PhpIconImage from "images/php-icon.svg";
import HtmlIconImage from "images/html5-icon.svg";

const Container = tw.div`relative`;
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;
// const Description = tw(SectionDescription)`w-full text-center`;
const VerticalSpacer = tw.div`mt-10 w-full`
const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;
const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 text-xl md:text-lg font-medium text-secondary-100 leading-loose`}
  }
`;

export default ({
    heading = <AppString id='features_heading' />, 
    description = <AppString id='features_description' /> 
}) => {

  const cards = [
    {
      imageSrc: JavascriptIconImage,
      title: <AppString id='features_javascript_title' />,
      description: <AppString id='features_javascript_description' />
    },
    { 
      imageSrc: MobileIconImage, 
      title: <AppString id='features_mobile_title' />,
      description: <AppString id='features_mobile_description' />
    },
    { 
      imageSrc: PhpIconImage, 
      title: <AppString id='features_php_title' />,
      description: <AppString id='features_php_description' />
    },
    { 
      imageSrc: WordpressIconImage, 
      title: <AppString id='features_wordpress_title' />,
      description: <AppString id='features_wordpress_description' />
    },
    { 
      imageSrc: NodeIconImage, 
      title: <AppString id='features_node_js_title' />,
      description: <AppString id='features_node_js_description' />
    },
    { 
      imageSrc: HtmlIconImage, 
      title: <AppString id='features_html_title' />,
      description: <AppString id='features_html_description' />
    }
  ];

  return (
    <Container id="habilidades">
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        {/* {description && <Description>{description}</Description>} */}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
