/* Links.js */
/* @author: anghello */

module.exports = Object.freeze({
    email: 'angelo.grdsilva@gmail.com',
    
    blog: 'https://area97.com.br',
    linkedin: 'https://www.linkedin.com/in/angelo-silva/',
    facebook: 'https://www.facebook.com/anghello.silva/',
    github: 'https://github.com/anghello'
});
