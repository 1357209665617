/* Home.js */
/* @author: anghello */

import React, { useEffect } from 'react';
import 'styled-components/macro';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import GoogleAnalytics from 'helpers/GoogleAnalytics';
import MetaTags from 'react-meta-tags';

import Hero from 'components/hero/SiteHero.js';
import Resume from 'components/features/SiteResume';
import Features from 'components/features/SiteFeatures';
import Projects from 'components/cards/SiteProjects';
import Contact from 'components/forms/SiteContact';
import Footer from 'components/footers/SiteFooter.js';
// import BlogCta from 'components/cta/SiteBlog';

const siteTitle = 'ANGHELLO - Desenvolvimento Web & Mobile';
const siteDescription = 'Angelo Silva, desenvolvedor web e mobile, cientista da computação. Desenvolvimento de sistemas, websites e aplicativos.';

export default () => {
  useEffect(() => { GoogleAnalytics(); });

  return (
    <AnimationRevealPage disabled>
      <MetaTags>
        <title>{siteTitle}</title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content={siteDescription} />

        <meta property="og:site_name" content="anghello.com.br" />
        <meta property="og:title" content={siteTitle} />
        <meta property="og:description" content={siteDescription} />
        <meta property="og:type" content="website" />

        <meta name="twitter:site" content="@Anghello_Silva" />
        <meta name="twitter:creator" content="@Anghello_Silva" />
        <meta name="twitter:title" content={siteTitle} />
        <meta name="twitter:description" content={siteDescription} />

        <link rel="canonical" href="https://anghello.com.br"/>
      </MetaTags>

      {/* Apresentação inicial */}
      <Hero />

      {/* Resumo completo */}
      <Resume />

      {/* Habilidades */}
      <Features />

      {/* Projetos */}
      <Projects />

      {/* Conheça o Blog */}
      {/* <BlogCta /> */}

      {/* Contato */}
      <Contact />

      {/* Rodapé */}
      <Footer />
    </AnimationRevealPage>
  );
}
