import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import SiteLinks from 'enums/Links';
import AppString from "i18n/AppString";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const SocialLinksContainer = tw.div`mt-12`
const SocialLink = tw.a`mt-4 text-left text-2xl`
const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-blue-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
    subheading = <AppString id='contact_title' />,
    heading = <><AppString id='contact_heading_1' /> <span tw="text-blue-500"><AppString id='contact_heading_2' /></span><wbr/></>,
    submitButtonText = <AppString id='contacr_submit_button_text' />,
    formAction = '#',
    formMethod = 'get',
    textOnLeft = true,
    showForm = false,
    showSocialLinks = true
}) => {

    return (
        <Container id="contato">
            <TwoColumn>
                <ImageColumn>
                    <Image imageSrc={EmailIllustrationSrc} />
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{heading}</Heading>
                        
                        {showSocialLinks && (
                            <SocialLinksContainer>
                                <SocialLink href={`mailto:${SiteLinks.email}`} target="_blank" rel="noreferrer noopener">{SiteLinks.email}</SocialLink>
                            </SocialLinksContainer>
                        )}

                        {showForm && (
                            <Form action={formAction} method={formMethod}>
                                <Input type="text" name="name" placeholder="Seu nome" />
                                <Input type="email" name="email" placeholder="Seu email" />
                                <Textarea name="message" placeholder="Sua mensagem" />
                                <SubmitButton type="submit">{submitButtonText}</SubmitButton>
                            </Form>
                        )}   

                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};
